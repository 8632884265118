import './EasySoftwareTable.scss'

import React, { FunctionComponent } from 'react'
import PuxWysiwyg, {
  PuxWysiwygHtmlBodyType,
} from 'Shared/components/PuxWysiwyg/PuxWysiwyg'

export interface EasySoftwareTableType {
  displayText: string
  tableContent: PuxWysiwygHtmlBodyType
}

const rootClass = `table`

const EasySoftwareTable: FunctionComponent<EasySoftwareTableType> = (props) => (
  <div className={rootClass}>
    <h2 className={`${rootClass}-title`}>{props.displayText}</h2>

    <div className={`${rootClass}-content`}>
      <PuxWysiwyg content={props.tableContent} />
    </div>
  </div>
)

export default EasySoftwareTable
